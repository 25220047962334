import { atom } from "recoil";

interface ITenant {
  id: string | undefined;
  translation: any;
  settings: {
    withLogo: boolean;
    iconPath?: string;
    height?: number;
    width?: number;
    mobileWidth?: number;
    footerIconPath?: string;
    fullName?: string;
    withArtifactLabsLogo?: boolean;
  };
}

const defaultValue: ITenant = {
  id: undefined,
  translation: {},
  settings: {
    withLogo: true,
    iconPath: "",
    footerIconPath: "",
    fullName: "Artifact Labs",
    withArtifactLabsLogo: true,
  },
};

const tenantAtom = atom({
  key: "tenantAtom",
  default: defaultValue,
});

export default tenantAtom;
