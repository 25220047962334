//

export const scmp_config = {
  //
  contactEmail: "",
  //
  licenseOptions: {
    EDITORIAL_USE_BROADCAST: {
      title: "Editorial use",
      showSuffix: true,
    },
    NON_PROFIT_INTERNAL_USE: {
      title: "Other use",
      showSuffix: true,
    },
  },
  //
  allowMobileViewOnDev: false,
  //
  licenseLink: `https://${process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME!}/documents/content-licensing/T-SCMP/Artevault_Content+Licensing+Agreement(SCMP).pdf`,

  termsAndConditionsLink: "https://www.scmp.com/terms-conditions",

  defaultCurrency: "USD",

  hideRelevantDatesFilter: true,

  searchResultTitleUseDescription: true, // DEV-2192

  showCategories: true,
};
