//

export const hkphil_config = {
  //
  contactEmail: "demo@artifactlabs.com",
  //
  allowMobileViewOnDev: false,

  hideCreationDateFilter: true,

  hideAssetTypeFilter: true,

  defaultCurrency: "HKD",

  isAddressMandatory: true,

  tooltipCopyrightText:
    "Copyright © The Hong Kong Philharmonic Society Limited. All rights reserved.",

  expirySectionName: "For records only",

  isDonationStyle: true,
};
