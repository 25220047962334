//

export const snm_config = {
  //
  contactEmail: "info@nationalmuseum.ch",
  //
  allowMobileViewOnDev: false,

  defaultCurrency: "USD",
};
