import en from "@/locales/en.json";
import ARTIFACTLABSFooterLogo from "@public/logos/artifactlabs-footer.svg";
import HKPHILFooterLogo from "@public/logos/hkphil-footer.svg";
import HKPHILLogo from "@public/logos/hkphil.jpg";
import MAPFooterLogo from "@public/logos/map-footer.svg";
import MAPLogo from "@public/logos/map.jpg";
import NGSFooterLogo from "@public/logos/ngs-footer.svg";
import NGSLogo from "@public/logos/ngs.svg";
import SCMPFooterLogo from "@public/logos/scmp-footer.svg";
import SCMPLogo from "@public/logos/scmp.svg";
import SNMFooterLogo from "@public/logos/snm-footer.svg";
import SNMLogo from "@public/logos/snm.png";
import SZABOTAGELogo from "@public/logos/szabotage.jpg";
import TitanicLogo from "@public/logos/titanic.svg";
import USNFooterLogo from "@public/logos/usn-footer.svg";
import USNLogo from "@public/logos/usn.png";

// TODO: get the config from server side
// TODO: integrate i18n when the requirement comes
export const getLocalizationProvider = (tenantId: string = ""): any => {
  if (tenantId.includes("ngs")) {
    return {
      ...en.translation.general,
      searchBar: {
        placeholder:
          "Discover the world of modern and contemporary art of Southeast Asia through the National Gallery Singapore's collection!",
      },
    };
  } else if (tenantId.includes("usn")) {
    return {
      ...en.translation.general,
      searchBar: {
        placeholder: "Discover the world of modern and contemporary art!",
      },
    };
  } else if (tenantId.includes("platform")) {
    return {
      ...en.translation.general,
      searchBar: {
        placeholder: "Discover the world of modern and contemporary collection!",
      },
    };
  } else if (tenantId.includes("snm")) {
    return {
      ...en.translation.general,
      ...en.translation.snm,
    };
  } else if (tenantId.includes("hkphil")) {
    return {
      ...en.translation.general,
    };
  } else if (tenantId.includes("map")) {
    return {
      ...en.translation.general,
      facet: {
        ...en.translation.general.facet,
        assetTypeFacet: "Medium",
      },
      metadata: {
        ...en.translation.general.metadata,
        assetType: "Medium",
      },
    };
  } else if (tenantId.includes("szabotage")) {
    return {
      ...en.translation.general,
    };
  } else {
    return {
      ...en.translation.general,
    };
  }
};

export const getSettingsByTenantId = (tenantId: string) => {
  if (tenantId.includes("general")) {
    return {
      withLogo: false,
      width: 48,
      withArtifactLabsLogo: true,
    };
  } else if (tenantId.includes("ngs")) {
    return {
      withLogo: true,
      width: 120,
      iconPath: NGSLogo,
      footerIconPath: NGSFooterLogo,
      fullName: "National Gallery Singapore",
      withArtifactLabsLogo: true,
    };
  } else if (tenantId.includes("titanic")) {
    return {
      withLogo: true,
      width: 106,
      iconPath: TitanicLogo,
      fullName: "Titanic",
      withArtifactLabsLogo: true,
    };
  } else if (tenantId.includes("scmp")) {
    return {
      withLogo: true,
      width: 186,
      mobileWidth: 122,
      iconPath: SCMPLogo,
      footerIconPath: SCMPFooterLogo,
      fullName: "South China Morning Post Publishers Ltd.",
      withArtifactLabsLogo: false,
    };
  } else if (tenantId.includes("usn")) {
    return {
      withLogo: true,
      width: 42,
      iconPath: USNLogo,
      footerIconPath: USNFooterLogo,
      fullName: "UltraSuperNew",
      withArtifactLabsLogo: true,
    };
  } else if (tenantId.includes("platform")) {
    return {
      withLogo: false,
      footerIconPath: ARTIFACTLABSFooterLogo,
      fullName: "Artifact Labs",
      withArtifactLabsLogo: true,
    };
  } else if (tenantId.includes("snm")) {
    return {
      withLogo: true,
      width: 42,
      iconPath: SNMLogo,
      footerIconPath: SNMFooterLogo,
      fullName: "Schweizerisches Nationalmuseum",
      withArtifactLabsLogo: true,
    };
  } else if (tenantId.includes("hkphil")) {
    return {
      withLogo: true,
      width: 64,
      iconPath: HKPHILLogo,
      footerIconPath: HKPHILFooterLogo,
      fullName: "The Hong Kong Philharmonic Society Limited",
      withArtifactLabsLogo: true,
    };
  } else if (tenantId.includes("map")) {
    return {
      withLogo: true,
      width: 80,
      iconPath: MAPLogo,
      footerIconPath: MAPFooterLogo,
      fullName: "Museum of Art & Photography",
      withArtifactLabsLogo: true,
    };
  } else if (tenantId.includes("szabotage")) {
    return {
      withLogo: true,
      width: 80,
      iconPath: SZABOTAGELogo,
      withArtifactLabsLogo: true,
    };
  } else {
    return {
      withLogo: false,
    };
  }
};

export const getTenantConfigs = (tenantId: string) => {
  return {
    settings: getSettingsByTenantId(tenantId),
    translation: getLocalizationProvider(tenantId),
    categories: getCategoriesByTenantId(tenantId),
  };
};

export const mapPlatformCopyrightToIssuer: Record<string, string> = {
  "GEM - Copyrighted": "Globe Photos",
  "SZABOTAGE - Copyrighted": "Szabotage",
  "ALEXANDER CHEAH - Copyrighted": "Alexander Cheah",
  "LINDSEY MCALISTER - Copyrighted": "Lindsey McAlister",
  "CLAUDIA LARCHER - Copyrighted": "Claudia Larcher",
  "JUSTIN LIM - Copyrighted": "Justin Lim",
};

// sets what exact filters are used per category per tenant
export const getCategoriesByTenantId = (
  tenantId: string,
): Record<string, Record<string, string>> => {
  switch (tenantId) {
    case "platform":
      return {
        all: {},
        iconicMoments: { creatorNameFacet: "AdNet||Kalani" },
        digitalArt: {
          creatorNameFacet: "Frank Nitty||Alexander Cheah||Ben Elliot||Claudia Larcher",
        },

        contemporaryArt: {
          creatorNameFacet: "Blink Gallery||Lindsey McAlister||Jacky Tsai",
        },

        visualCulture: {
          creatorNameFacet: "Lindsey McAlister||Szabotage",
        },

        photography: {
          creatorNameFacet: "Justin Lim",
        },
      };
    case "snm":
    case "usn":
    case "hkphil":
    case "map":
    case "titanic":
    case "ngs":
    case "szabotage":
      return {
        all: {},
      }; // nothing specific. just "All" categories
    default: // includes "scmp"
      return {};
  }
};
