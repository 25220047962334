import { default_config } from "@/utils/org/configs/default.config";
import { hkphil_config } from "@/utils/org/configs/hkphil.config";
import { map_config } from "@/utils/org/configs/map.config";
import { ngs_config } from "@/utils/org/configs/ngs.config";
import { platform_config } from "@/utils/org/configs/platform.config";
import { scmp_config } from "@/utils/org/configs/scmp.config";
import { snm_config } from "@/utils/org/configs/snm.config";
import { szabotage_config } from "@/utils/org/configs/szabotage.config";
import { titanic_config } from "@/utils/org/configs/titanic.config";
import { usn_config } from "@/utils/org/configs/usn.config";

export interface IOrganizationAuth0Config {
  domain: string;
  clientId: string;
}

export interface IOrganizationConfig {
  // Tenant Settings
  defaultLicenseDetails?: any;
  licenseOptions?: any;
  defaultCurrency: string;

  // UI toggle - User Profile
  isAddressMandatory?: boolean;

  // UI toggle - Artwork Detail
  hideContractInfo?: boolean;

  // UI toggle - My Collection
  showLicenseLinkOnMyCollectionPage?: boolean;

  // UI toggle - Search Result Filters
  hideCreationDateFilter?: boolean;
  hideAssetTypeFilter?: boolean;
  hideRelevantDatesFilter?: boolean;
  hideCreatorFilter?: boolean;
  showIssuerFilter?: boolean;
  relevantGeosFacetShowSearchbarFilter?: boolean;
  assetTypeFacetShowSearchbarFilter?: boolean;
  // DEV-2192: use the search result title as description. default is name
  searchResultTitleUseDescription?: boolean;

  // UI toggle - styles
  isDonationStyle?: boolean;

  // UI toggle - others
  allowMobileViewOnDev: boolean;

  // Wording / Strings
  contactEmail?: string;
  licenseLink: string; // there's a default fallback if unspecified
  tooltipCopyrightText?: string;
  expirySectionName?: string;
  termsAndConditionsLink: string; // there's a default fallback if unspecified

  showRelatedArtworks?: boolean;

  // [DEV-2060] main flag
  showCategories: boolean;

  // [DEV-2060] only applicable when showCategories is true
  responsiveHomeAssetsCount: {
    mobile: number;
    desktop: number;
  };
}

//
export const getOrgConfigByTenantId = (tenantId: string = ""): IOrganizationConfig => {
  // override default config with tenant specific config
  switch (tenantId) {
    case "scmp":
      return { ...default_config, ...scmp_config };
    case "platform":
      return { ...default_config, ...platform_config };
    case "snm":
      return { ...default_config, ...snm_config };
    case "usn":
      return { ...default_config, ...usn_config };
    case "hkphil":
      return { ...default_config, ...hkphil_config };
    case "map":
      return { ...default_config, ...map_config };
    case "titanic":
      return { ...default_config, ...titanic_config };
    case "ngs":
      return { ...default_config, ...ngs_config };
    case "szabotage":
      return { ...default_config, ...szabotage_config };
    default:
      return default_config;
  }
};

export interface OrganizationAuth0Config {
  domain: string;
  clientId: string;
}

type TenantConfigMap = {
  [key: string]: {
    domain: string;
    clientId: string;
  };
};

export const TENANT_AUTH0_CONFIG: TenantConfigMap = {
  scmp: {
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN_SCMP ?? "",
    clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID_SCMP ?? "",
  },
  default: {
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN_DEFAULT ?? "",
    clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID_DEFAULT ?? "",
  },
} as const;

export const getOrgAuth0ConfigByTenantId = (
  tenantId: string | undefined,
): OrganizationAuth0Config => {
  if (!tenantId) return { domain: "", clientId: "" };

  const config = TENANT_AUTH0_CONFIG[tenantId?.toLowerCase()] || TENANT_AUTH0_CONFIG.default;

  if (!config.domain || !config.clientId) {
    throw new Error(`Missing Auth0 configuration for tenant: ${tenantId}`);
  }

  return config;
};
